import * as types from './types';
import * as authTypes from '../auth/types';
import UsersService from '../../service/users';

/** метод получения данных текущего пользователя */
export const fetchCurrentUser = () => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });
    const response = await UsersService.fetchCurrentUser();
    dispatch({ type: types.FETCH_CURRENT_USER, userData: response });
    dispatch({ type: authTypes.SET_LOG_IN_STATUS, logged: !!response?.id });
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};
