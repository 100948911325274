import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { chartColors } from '../../../config/colors';

import '../style.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);  

/** компонент отрисовки barchart'a сравнительных показателей показателей (3 пункта в одном bar'е) */
export default function CompareBarChart(props) {
  const {
    /** наименование графика */
    name,
    /** данные графика */
    dataset,
    /** маркер малого размера устройства */
    smallScreen,
    /** заголовок графика */
    titleText,
    /** дополнительные символы в тултипе */
    tooltip,
    /** границы графика */
    border,
    /** отображать ли ось У */
    showScaleY,
  } = props;

  /** t - функция, которая дает доступ к конфигу translations */
  const { t } = useTranslation();

  /** массив значений "Лучше" (ось Y) */
  const [better, setBestData] = useState([]);
  /** массив значений "Не хуже" (ось Y) */
  const [same, setSameData] = useState([]);
  /** массив значений "Хуже" (ось Y) */
  const [worse, setWorseData] = useState([]);
  /** список label'ов (timestamp'ов) графиков (ось Х) */
  const [timestamp, setTimestamp] = useState([]);

  useEffect(() => {
    const data = dataset?.map(elem => elem?.data);
    setBestData(data?.map(elem => elem?.better ?? elem?.best));
    setSameData(data?.map(elem => elem?.same));
    setWorseData(data?.map(elem => elem?.worse ?? elem?.worst));
    setTimestamp(dataset?.map(elem => moment(elem?.timestamp).format('HH:mm')));
  }, [dataset]);

  const chartData = {
    labels: timestamp, 
    datasets: [
      {
        label: t('charts.' + name + '.better'),
        data: better,
        backgroundColor: chartColors.green,
      },
      {
        label: t('charts.' + name + '.same'),
        data: same,
        backgroundColor: chartColors.blue,
      },
      {
        label: t('charts.' + name + '.worse'),
        data: worse,
        backgroundColor: chartColors.red,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      /** заголок графика */
      title: {
        display: false,
        text: titleText,
        align: smallScreen ? 'start' : 'center',
      },
      /** легенада (описание bar'а) */
      legend: {
        display: true,
        align: 'end',
        labels: {
          boxWidth: 5,
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => tooltip ? `${context?.formattedValue} ${tooltip}` : context?.formattedValue,
        },
      },
    },
    scales: {
      /** ось Х */
      x: {
        /** признак того, что bar chart делится на деления (хуже, не хуже, лучше) */
        stacked: true,
        /** количество отрезков по оси Х (для малых экранов их меньше, чем для больших) */
        ticks: { maxTicksLimit: smallScreen ? 11 : 20 },
      },
      /** ось У */
      y: {
        title: {
          display: true,
          text: '%'
        },
        display: showScaleY,
        /** признак того, что bar chart делится на деления (хуже, не хуже, лучше) */
        stacked: true,
        /** мин и макс значения по оси У (3 деления в сумме дают 100%) */
        min: 0,
        max: border ? 100 : undefined,
        /** количество отрезков по оси У */
        ticks: { maxTicksLimit: 10 },
      },
    },
  };

  return (
    <Fragment>
      <Bar options={options} data={chartData} />
    </Fragment>
  )
}
