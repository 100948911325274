import React, { Fragment, useRef, useState } from 'react';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import cookies from 'js-cookie';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Public from '@mui/icons-material/Public';

import { colors } from '../../config/colors';
import { languages } from '../../config/language';

import './style.css';

/** компонент выбора языка интерфейса */
export default function Language(props) {
  const {
    /** маркер малого размера устройства */
    smallScreen,
  } = props;

  /** t - функция, которая дает доступ к конфигу translations */
  const { t } = useTranslation();

  /** код текущего языка, по умолчанию - en */
  const currentLanguageCode = cookies?.get('i18next') ?? 'en';
  /** индекс текущего языка (для предустановки пункта меню) */
  const currentLanguageIndex = languages?.findIndex(elem => elem?.code === currentLanguageCode);

  /** ссылка на элемент, к которому будет прикрепляться Popper (меню выбора языка) - в данном случае кнопка с выбором языка */
  const anchorRef = useRef(null);

  /** маркер - отображать ли меню выбора языка */
  const [open, setOpen] = useState(false);
  /** индекс и метод установки индекса текущего языка */
  const [selectedIndex, setSelectedIndex] = useState(currentLanguageIndex);

  /** метод-обработчик кнопки выбора языка */
  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /** метод-обработчик пункта меню */
  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    setOpen(false);
    /** установка значения текущего языка в конфиге */
    i18next.changeLanguage(languages?.find((elem, ind) => ind === index)?.code);
  };

  /** метод-обработчик взаимодействия со всем пространством за исключением меню выбора языка (ClickAwayListener) */
  const handleClose = (event) => {
    if (anchorRef?.current.contains(event?.target)) return;
    setOpen(false);
  };

  return (
    <Fragment>
      <Box ref={anchorRef} sx={{ display: 'flex', flexDirection: 'row' }}>
        <Button
          startIcon={<Public sx={{ color: smallScreen ? colors.blue : colors.lightBlue, fontSize: '20px' }} />}
          onClick={handleClick}
        >
          {t("lang")}
        </Button>
      </Box>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        className="popper"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={0} variant="outlined">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {
                    languages.map((option, index) => (
                      <MenuItem
                        key={option.code}
                        selected={index === selectedIndex}
                        disabled={index === selectedIndex}
                        onClick={() => handleMenuItemClick(index)}
                      >
                        {option.name}
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
