import React from 'react';

// import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import './style.css';
import Link from "@mui/material/Link";

/** компонент отрисовки футера */
export default function Footer() {
  /** t - функция, которая дает доступ к конфигу translations */
  // const { t } = useTranslation();m

  return (
    <Typography className="footer-text">  <Link href="https://finmarketsoft.ru" underline="none">Разработано в finmarketsoft.ru</Link> </Typography>
  )
}
