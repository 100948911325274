import axios from 'axios';

/** сервис авторизации */
export default class AuthService {
  static baseApiUrl = 'api/v1/auth';

  /** метод регистрации пользователя
   *  параметры пользователя при регистрации
   *  @param data = {
   *    @param {string} email (required)
   *    @param {string} first_name (required)
   *    @param {string} last_name (required)
   *    @param {string} password заполняется автоматически
   *  }
   */
  static register(data) {
    const params = {
      first_name: data?.name,
      last_name: data?.surname,
      email: data?.email,
      password: Math.random().toString(36).slice(-8)
    };

    return axios
      .post(`${this.baseApiUrl}/register`, params)
      .then(res => res?.data)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод авторизации (логина)
   *  параметры пользователя при авторизации
   *  @param data = {
   *    @param {string} username (required)
   *    @param {string} password (required)
   *    @param {boolean} rememberMe - TODO: параметр пока не используется
   *  }
   */
  static login(data) {
    /** данные формы */
    const formData = new FormData();
    formData.set('username', data?.login);
    formData.set('password', data?.password);

    return axios
      .post(
        `${this.baseApiUrl}/login`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      )
      .then(res => res?.data)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод выхода из аккаунта (логаута) */
  static logout() {
    return axios
      .post(`${this.baseApiUrl}/logout`)
      .then(res => res?.data)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод восстановление пароля
   *  @param data = {
   *    @param {string} email (required)
   *  }
   */
   static forgotPassword(data) {
    return axios
      .post(`${this.baseApiUrl}/refresh-password`, data)
      .then(res => res.data)
      .catch(error => {
        return Promise.reject(error);
      });
  }
}