import { getRequestParams } from '../charts/reducers';
import * as chartTypes from '../charts/types';
import * as types from './types';
import InformationService from '../../service/information';

/** метод загрузки данных рынков */
export const fetchMarkets = () => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });
    const response = await InformationService.fetchMarkets();
    dispatch({
      type: types.FETCH_MARKETS,
      data: response ?? [],
    });
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    return response ?? [];
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод загрузки данных инструментов */
export const fetchTickers = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });
    const response = await InformationService.fetchTickers({ market: data?.market, date: data?.date, group: data?.group });
    dispatch({
      type: types.FETCH_TICKERS,
      data: response?.map(elem => ({ label: elem?.name, symbol: elem?.symbol })) ?? [],
    });
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    return response ?? [];
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод загрузки данных доступных дат */
export const fetchDates = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });
    const response = await InformationService.fetchDates({ market: data?.market });
    dispatch({
      type: types.FETCH_DATES,
      data: response ?? [],
    });
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    return response ?? [];
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод загрузки инит значений инструментов */
export const fetchInitInformationData = () => async (dispatch, getState) => {
  try {
    const markets = await dispatch(fetchMarkets());
    const defaultMarket = markets?.find(elem => elem?.default)?.market ?? '';

    const dates = await dispatch(fetchDates({ market: defaultMarket }));
    const defaultDay = dates[dates?.length - 1] ?? '';

    const tickers = await dispatch(fetchTickers({ market: defaultMarket, date: defaultDay }));

    dispatch({
      type: chartTypes.SET_REQUEST_DATA,
      params: {
        ...getRequestParams(getState()),
        market: defaultMarket,
        symbol: tickers?.find(elem => elem?.default)?.symbol ?? '',
        date: defaultDay,
      },
    });
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};
