import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import * as serviceActions from '../../store/service/actions';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Form from '../Form';

import './style.css';

/** компонен отрисовки главной страницы */
function Main(props) {
  const {
    /** установка размера экрана устройства */
    setScreenSize,
  } = props;

  const updateWidthAndHeight = () => {
    setScreenSize(window.innerWidth);
  };

  /** иниициализируем размер экрана устройства */
  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, [setScreenSize]);

  /** динамически отслеживаем размер окна и изменяем значение переменной ширины окна */
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return (
    <Fragment>
      <section className="wrapper">
       <article className="header">
          <Header showTranslationBtn={false} showLogInBtn={true} />
        </article>
        <article className="main">
          <Form />
        </article>
        <article className="footer">
          <Footer />
        </article>
      </section>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setScreenSize: (data) => dispatch(serviceActions.setScreenSize(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main)