import * as types from './types';

const initState = {
  status: {
    loading: false,
    success: false,
    error: false,
  },
  tickers: [],
  markets: [],
  dates: {
    listDates: [],
    minDate: '',
    maxDate: '',
  },
};

export default function reduce(state = initState, action = {}) {
  switch (action.type) {
    case types.FETCH_TICKERS:
      return {
        ...state,
        tickers: action?.data,
      };

    case types.FETCH_MARKETS:
      return {
        ...state,
        markets: action?.data,
      };

    case types.FETCH_DATES:
      return {
        ...state,
        dates: {
          ...state?.dates,
          listDates: action?.data,
          minDate: action?.data[0],
          maxDate: action?.data[action?.data?.length - 1],
        },
      };

    case types.SET_LOADING_STATUS:
      return {
        ...state,
        status: {
          ...state?.status,
          loading: action?.loading,
          success: action?.success,
          error: action?.error,
        },
      };

    default:
      return state;
  }
};

/** список рынков */
export const getMarkets = state => {
  return state?.information?.markets;
};

/** список тикеров */
export const getTickers = state => {
  return state?.information?.tickers;
};

/** список дат */
export const getDates = state => {
  return state?.information?.dates;
};

/** статус загрузки данных */
export const getLoadingStatus = state => {
  return state?.information?.status;
};
