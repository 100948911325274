import React from 'react';

import MUIBackdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import { colors } from '../../config/colors';

import './style.css';

/** компонент отрисовки индикатора загрузки */
export default function Backdrop(props) {
  const {
    /** маркер - отображать ли индикатор загрузки */
    open,
    /** маркер - нужно ли отцентровать индикатор загрузки по вертикали (нужно для некоторых небольших блоков) */
    center,
  } = props;

  const CustomBackdrop = styled(MUIBackdrop)({
    position: 'absolute',
    zIndex: 100,
    backgroundColor: colors.darkBlue,
    opacity: '0.6 !important',
    display: 'flex',
    alignItems: center ? 'center' : 'flex-start',
    padding: '15% 0',
  });

  return (
    <CustomBackdrop open={open}>
      <CircularProgress sx={{ color: colors.green }} />
    </CustomBackdrop>
  )
}
