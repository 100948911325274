import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_HOST;

/** Установка обработчиков запросов */
const setupAxiosInterceptors = () => {
  if (axios.interceptors) {
    axios.interceptors.response.use(res => res);
  }
};

export default setupAxiosInterceptors;
