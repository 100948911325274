import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { chartColors } from '../../../config/colors';
import '../style.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
); 

const colorsQueue = [chartColors.blue, chartColors.green, chartColors.lightBlue, chartColors.gray, chartColors.red];

/** компонент отрисовки графика ValuesInside */
export default function ValuesInsideLineChart(props) {
  const {
    /** данные графика */
    dataset,
    /** маркер малого размера устройства */
    smallScreen,
    /** заголовок графика */
    titleText,
    /** отображать ли ось У */
    showScaleY,
    /** цвет графика */
    barColor,
  } = props;

  /** массив значений "volume" (ось Y) */
  const [volume, setVolumeData] = useState([]);
  /** список label'ов (timestamp'ов) графиков (ось Х) */
  const [timestamp, setTimestamp] = useState([]);
  /** валюта */
  const [currency, setCurrency] = useState('');
  /** цвет графика */
  const [color, seColor] = useState(colorsQueue[0]);

  const getRandomColor = () => {
    return barColor ?? colorsQueue[Math.floor(Math.random() * colorsQueue?.length)];
  }

  useEffect(() => {
    /** выбираем из исходного датасета данные для оси Х и переводим их в формат 'HH:mm' */
    setTimestamp(dataset?.map(elem => moment(elem?.timestamp).format('HH:mm')));
    setVolumeData(dataset?.map(elem => elem?.data));
    // setCurrency(dataset?.[0]?.currency);
    seColor(getRandomColor());

    // eslint-disable-next-line
  }, [dataset]);

  const chartData = {
    labels: timestamp, 
    datasets: [
      {
        label: currency,
        data: volume,
        backgroundColor: color,
        borderColor: color,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      /** заголовок графика */
      title: {
        display: true,
        text: titleText,
        align: smallScreen ? 'start' : 'center',
      },
      legend: {
      /** легенада (описание line) */
        display: false,
        align: 'end',
        labels: {
          boxWidth: 5,
          usePointStyle: true,
        },
      },
    },
    scales: {
      /** ось Х */
      x: {
        ticks: {
          /** количество отрезков по оси Х (для малых экранов их меньше, чем для больших) */
          ticks: { maxTicksLimit: smallScreen ? 11 : 20 },
        },
      },
      /** ось У */
      y: {
        title: {
          display: true,
          text: 'Объем торгов, долл. США'
        },
        display: showScaleY,
      },
    },
  };

  return (
    <Fragment>
      <Line options={options} data={chartData} />
    </Fragment>
  )
}
