import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { chartColors } from '../../../config/colors';

import '../style.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);  

const colorsQueue = [chartColors.blue, chartColors.green, chartColors.lightBlue, chartColors.red];

/** компонент отрисовки barchart'a сравнительных показателей показателей (динамическое количество делений в одном bar'е) */
export default function VolumesBarChart(props) {
  const {
    /** данные графика */
    dataset,
    /** маркер малого размера устройства */
    smallScreen,
    /** заголовок графика */
    titleText,
    /** дополнительные символы в тултипе */
    tooltip,
    /** отображать ли ось У */
    showScaleY,
  } = props;

  /** список label'ов (timestamp'ов) графиков (ось Х) */
  const [timestamp, setTimestamp] = useState([]);
  /** список данных по рынкам (ось У) */
  const [chartDataset, setDatasets] = useState([]);

  useEffect(() => {
    /** выбираем из исходного датасета данные для оси Х и переводим их в формат 'HH:mm' */
    setTimestamp(dataset?.map(elem => moment(elem?.timestamp).format('HH:mm')));
    const data = dataset?.map(elem => elem?.data) ?? [];
    /** из первой записи выбираем все ключи (названия рынков) */
    const markets = !!data.length ? Object.keys(data.reduce((a, b) => Object.keys(a).length >= Object.keys(b).length ? a : b)) : [];
    /** для каждого рынка указываем заголовок графика, цвет и список значений по оси Y, соответствующих каждой точке оси X */
    const marketsData = markets?.map((market, ind) => {
      return {
        label: market,
        data: data?.map(item => item[market]?.volume),
        backgroundColor: colorsQueue[ind],
        borderColor: colorsQueue[ind],
      };
    });
    setDatasets(marketsData);
  }, [dataset]);

  const chartData = {
    labels: timestamp, 
    datasets: chartDataset,
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      /** заголок графика */
      title: {
        display: false,
        text: titleText,
        align: smallScreen ? 'start' : 'center',
      },
      /** легенада (описание bar'а) */
      legend: {
        display: true,
        align: 'end',
        labels: {
          boxWidth: 5,
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => tooltip ? `${context?.formattedValue} ${tooltip}` : context?.formattedValue,
        },
      },
    },
    scales: {
      /** ось Х */
      x: {
        /** признак того, что bar chart делится на деления (хуже, не хуже, лучше) */
        stacked: false,
        /** количество отрезков по оси Х (для малых экранов их меньше, чем для больших) */
        ticks: { maxTicksLimit: smallScreen ? 11 : 20 },
      },
      /** ось У */
      y: {
        title: {
          display: true,
          text: 'Объем торгов, долл. США'
        },
        display: showScaleY,
        /** признак того, что bar chart делится на деления (хуже, не хуже, лучше) */
        stacked: false,
        /** количество отрезков по оси У */
        ticks: { maxTicksLimit: 10 },
      },
    },
  };

  return (
    <Fragment>
      <Bar options={options} data={chartData} />
    </Fragment>
  )
}
