import React, { Fragment } from 'react'

import { connect } from 'react-redux';

import * as authSelectors from '../../store/auth/reducers';
import * as serviceSelectors from '../../store/service/reducers';

import MUICard from '@mui/material/Card';
import { styled } from '@mui/material/styles';


import ChartHeader from './ChartHeader';
import ChartContent from './ChartContent';
import ChartComment from './ChartComment';

import './style.css';

/** компонент отрисовки карточки с графиками */
function Chart(props) {
  const {
    /** данные графика */
    data,
    /** тип графика */
    type,
    /** маркер - на карточке распологается только один, а не два графика */
    singleChart,

    /** === store === */
    /** маркер - залогинен ли пользователь */
    logged,
    /** маркер малого размера устройства */
    smallScreen,
    /** размер экрана */
    screenSize,
  } = props;

  const Card = styled(MUICard)(({ theme }) => ({
    /** breakpoint - 1024px */
    [theme.breakpoints.down(1024)]: {
      width: '100%',
    },
    [theme.breakpoints.up(1024)]: {
      width: singleChart && !smallScreen ? '49%': '100%',
    },
  }));


  return (
    <Fragment>
      <Card
        className="chart"
        sx={{
          boxShadow: '0px 1px 25px rgba(99, 101, 152, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 3%',
        }}
      >
        <section className="chart-header">
          <ChartHeader
            smallScreen={smallScreen}
            type={type}
            logged={logged}
          />
        </section>

        <section className={`${singleChart ? 'chart-main_single' : 'chart-main'}`}>
          <ChartContent type={type} data={data} smallScreen={smallScreen} screenSize={screenSize} />
        </section>

        <section className="chart-comment">
          <ChartComment type={type} screenSize={screenSize} />
        </section>
      </Card>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    logged: authSelectors.getLogInStatus(state),
    smallScreen: serviceSelectors.getSmallScreen(state),
    screenSize: serviceSelectors.getScreenSize(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Chart)
