import React from 'react';

import MUIAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import './style.css';

/** компонент отрисовки информационных сообщений */
export default function Alert(props) {
  const {
    /** маркер - отображать ли инф сообщение */
    open,
    /** текст инф сообщения */
    title,
    /** тип инф сообщенияя (по умолчанию - error) */
    type,
    /** метод-обработчик кнопки сокрытия инф сообщения */
    handleClose,
  } = props;

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      onClose={handleClose}
    >
      <MUIAlert
        variant="outlined"
        severity={type ?? 'error'}
        onClose={handleClose}
        sx={{ width: '100%' }}
      >
        { title }
      </MUIAlert>
    </Snackbar>
  )
}
