import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { colors } from '../../config/colors';

import './style.css';

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ color: colors.gray }} />}
    {...props}
  />
))(() => ({
  flexDirection: 'row-reverse',
  padding: '0 7px',
  minHeight: '40px !important',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-content': {
    fontSize: '14px',
    color: colors.gray,
    margin: 0,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
}));

/** компонент отрисовки комментария карточки графиков */
export default function ChartComment(props) {
  const {
    /** типа графика */
    type,
    /** размер экрана */
    screenSize,
  } = props;

  /** t - функция, которая дает доступ к конфигу translations */
  const { t } = useTranslation();

  /** эксперт */
  const getExpertText = () => {
    return t(`charts.${type}.expert`);
  }

  /** лого эсперта */
  const getExpertLogo = () => {
    return t(`charts.${type}.expert_logo`);
  }

  /** текст комментария */
  const getCommentText = () => {
    return t(`charts.${type}.comment`);
  }

  return (
    <Fragment>
      {
        (screenSize <= 768) &&
          <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{ t('comment_title') }</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', width: '100%', minWidth: '230px' }}>
                <Typography sx={{ color: colors.darkBlue, fontSize: '12px',  }}>
                  { getCommentText() ?? '' }
                </Typography>
                {/*<Typography sx={{ color: colors.darkBlue, fontSize: '12px', fontWeight: 'bold', margin: '10px 0 0 0' }}>*/}
                {/*  { getExpertText() ?? '' }*/}
                {/*</Typography>*/}
              </Box>
            </AccordionDetails>
          </Accordion>
      }

      {
        (screenSize > 768) &&
          <Fragment>
            {/*<Avatar*/}
            {/*  sx={{ bgcolor: colors.violet, width: '60px', height: '60px', alignSelf: 'flex-end' }}*/}
            {/*  src={ getExpertLogo() }*/}
            {/*/>*/}
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', width: '100%', minWidth: '230px' }}>
              <Typography sx={{ color: colors.darkBlue, fontSize: '12px',  }}>
                { getCommentText() ?? '' }
              </Typography>
              {/*<Typography sx={{ color: colors.darkBlue, fontSize: '12px', fontWeight: 'bold', margin: '10px 0 0 0' }}>*/}
              {/*  { getExpertText() ?? '' }*/}
              {/*</Typography>*/}
            </Box>
          </Fragment>
      }
    </Fragment>
  )
}
