/** возможные варианты языка интерфейса */
export const languages = [
  {
    code: 'ru',
    name: 'Русский',
  },
  {
    code: 'en',
    name: 'English',
  },
];
