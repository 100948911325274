import axios from 'axios';
import {store} from "../store/configureStore";
import * as authTypes from "../store/auth/types";

export default class UsersService {
  static baseApiUrl = 'api/v1/users';

  /** метод получения данных текущего юзера */
  static fetchCurrentUser() {
    return axios
      .get(`${this.baseApiUrl}/me`)
      .then(res => res?.data ?? {})
      .catch(error => {
        store.dispatch({ type: authTypes.SET_LOG_IN_STATUS, logged: false });
        return Promise.reject(error);
      });
  }
}