import React, { Fragment, useEffect } from 'react';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import * as chartsActions from '../../store/charts/actions';
import * as informationActions from '../../store/information/actions';
import * as usersActions from '../../store/users/actions';
import * as authSelectors from '../../store/auth/reducers';
import * as chartsSelectors from '../../store/charts/reducers';
import * as serviceSelectors from '../../store/service/reducers';

import Typography from '@mui/material/Typography';

import Backdrop from '../../components/BackDrop';
import Chart from '../Chart';
import Toolbar from '../Toolbar';

import './style.css';

/** компонен отрисвки формы главной страницы */
function Form(props) {
  const {
    /** === store === */
    /** маркер малого размера устройства */
    smallScreen,
    /** статус загрузки данных авторизации */
    authLoadingStatus,
    /** статус загрузки данных графиков */
    loadingStatus,
    /** данные параметров запроса */
    requestParams,
    /** данные графиков */
    chartsData,

    /** метод получения данных графиков */
    fetchChartsData,
    /** метод получения данных текущего юзера */
    fetchCurrentUser,
    /** метод инит загрузки инструментов */
    fetchInitInformationData,
   } = props;

  /** t - функция, которая дает доступ к конфигу translations */
  const { t } = useTranslation();

  useEffect(() => {
      fetchCurrentUser()
      .then(() => fetchInitInformationData());
  
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requestParams?.market && requestParams?.symbol) {
      fetchChartsData(requestParams);
    }

    // eslint-disable-next-line
  }, [requestParams]);

  return (
    <Fragment>
      <div className={`${smallScreen? 'form-wrapper_small' : 'form-wrapper'}`}>
        {
          !smallScreen &&
            <section className="form-banner">
              <Backdrop open={loadingStatus?.loading || authLoadingStatus?.loading} center={true} />

              <img src="/img/large_banner.png" alt="" />
              <article className="form-banner-text">
                <Typography className="banner-text banner-text__title" sx={{ color: '#ffffff', fontWeight: 700 }}>
                  {t("market_quality")}
                </Typography>
                <Typography className="banner-text banner-text__content" sx={{ color: '#ffffff' }}>
                  {t("text.banner_content_service")}
                </Typography>
                <Typography className="banner-text banner-text__content" sx={{ color: '#ffffff' }}>
                  {t("text.banner_content_indicator")}
                </Typography>
              </article>
            </section>
        }

        <div className="form-main">
          <Backdrop open={loadingStatus?.loading || authLoadingStatus?.loading} />

          {/* компонент параметров настройки данных графиков */}
          <Toolbar />

          <Chart
            type="compare_sides"
            data={chartsData?.compareSidesCharts}
          />

          <Chart
            type="compare_spreads"
            data={chartsData?.compareSpreadsCharts}
          />

          <div className="single-charts-wrapper">
            <Chart
              type="spread_values"
              data={chartsData?.spreadValuesCharts}
              singleChart={true}
            />

            <Chart
              type="delta_spread"
              data={chartsData?.deltaSpreadCharts}
              singleChart={true}
            />
          </div>

          <Chart
            type="volumes"
            data={chartsData?.volumesCharts}
          />

          <Chart
            type="values_inside"
            data={chartsData?.valuesInsideCharts}
          />

          {/* <div className="single-charts-wrapper">
            <Chart
              type="delta_execution"
              data={chartsData?.deltaExecutionCharts}
              singleChart={true}
            />

            <Chart
              type="compare_execution"
              data={chartsData?.compareExecutionCharts}
              singleChart={true}
            />
          </div> */}
        </div>
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    authLoadingStatus: authSelectors.getLoadingStatus(state),
    loadingStatus: chartsSelectors.getLoadingStatus(state),
    chartsData: chartsSelectors.getChartsData(state),
    requestParams: chartsSelectors.getRequestParams(state),
    smallScreen: serviceSelectors.getSmallScreen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChartsData: (data) => dispatch(chartsActions.fetchChartsData(data)),
    fetchCurrentUser: () => dispatch(usersActions.fetchCurrentUser()),
    fetchInitInformationData: () => dispatch(informationActions.fetchInitInformationData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Form)
