import React, { Fragment } from 'react';

import { useNavigate } from 'react-router-dom';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import * as authActions from '../../store/auth/actions';
import * as chartsActions from '../../store/charts/actions';
import * as authSelectors from '../../store/auth/reducers';
import * as serviceSelectors from '../../store/service/reducers';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Typography from '@mui/material/Typography';

import CustomButton from '../Button';
import Language from '../Language';

import { colors } from '../../config/colors';
import { router } from '../../config/router';

import './style.css';

/** компонент отрисовки хедера */
function Header(props) {
  const {
    /** маркер - отображать ли кнопку входа/выхода */
    showLogInBtn,
    /** маркер - отображать ли кнопку входа/выхода */
    showTranslationBtn,
    /** === store === */
    /** маркер малого размера устройства */
    smallScreen,
    /** маркер - залогинен ли пользователь */
    logged,
    /** метод log out'a */
    logOut,
    /** метод установки в стор данных запросов */
    setRequestData,
  } = props;

  /** t - функция, которая дает доступ к конфигу translations */
  const { t } = useTranslation();
  /** метод перехода по указанному path */
  const navigate = useNavigate();

  /** метод-обработчик кнопки входа/выхода */
  const onHandleClick = async () => {
    setRequestData({ market: '', symbol: '', date: '', group: '' });

    /** если пользователь НЕ залогинен, то при нажатии на кнопку входа, пользователя перекинет на форму логина */
    if (!logged) {
      navigate(router.login);
      return;
    }

    /** если пользователь залогинен, то при нажатии на кнопку выхода выполняется метод log out'a и пользователя перекинет на главную страницу */
    await logOut();
    window.location.reload();
  }

  return (
    <Fragment>
      {
        smallScreen &&
          <Box sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...showLogInBtn && { minHeight: '150px' },
          }}>
            <Typography sx={{ color: colors.darkBlue, fontWeight: 700, fontSize: '25px' }}>
              {t("market_quality")}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
              {/* компонент выбора языка */}
              { showTranslationBtn &&
                <Language smallScreen={smallScreen}/>
              }

              {/* кнопка входа/выхода */}
              {
                showLogInBtn &&
                  <Button
                    startIcon={
                      logged
                        ? <LogoutOutlinedIcon sx={{ color: colors.blue, fontSize: '25px' }} />
                        : <LoginOutlinedIcon sx={{ color: colors.blue, fontSize: '25px' }} />
                    }
                    onClick={onHandleClick}
                    sx={{ color: colors.blue, fontWeight: 'bold' }}
                  >
                    {logged ? t("btns.log_out_btn_label") : t("btns.log_in_btn_label")}
                  </Button>
              }
            </Box>
          </Box>
      }
      {
        !smallScreen &&
          <Box sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            {/* компонент выбора языка */}
            { showTranslationBtn &&
              <Language smallScreen={smallScreen}/>
            }

            {/* кнопка входа/выхода */}
            {
              showLogInBtn &&
              <CustomButton
                onClick={onHandleClick}
                title={logged ? t("btns.log_out_btn_label") : t("btns.log_in_btn_label")}
              />
            }
          </Box>
      }
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    logged: authSelectors.getLogInStatus(state),
    smallScreen: serviceSelectors.getSmallScreen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(authActions.logOut()),
    setRequestData: (data) => dispatch(chartsActions.setRequestData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header)