import axios from 'axios';
import HelperService from './helper';

export default class ChartService {
  static baseApiUrl = 'api/v1/charts';

  /** метод получения данных 'Сравнение лучших цен по сторонам между рынками'
   *  (Используется для получения результатов сравнения лучших цен по инструменту между рынками, агрегированные за указанный период)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {number} period
   *    @param {string} date
   *  }
   */
  static fetchCompareSidesData(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/compare/sides${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения данных 'Результат сравнения спредов'
   *  (Возвращает список итогов сравнения спредов между spb с nasdaq по всему рынку агрегированные за 5 минут.
   *   В качестве параметров можно указать иную агрегацию (TODO Доступные значения) тикер, в секундах или другой рынок для сравнения.)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {number} period
   *    @param {string} date
   *  }
   */
   static fetchCompareSpreadsData(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/compare/spreads${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения данных 'Профиль размера спреда' (Размер средневзвешенного размера спреда на рынках в ВР)
   *  (Используется для получения значений средневзвешенного спреда по инструменту для основного и сравниваемого рынка, агрегированные за указанный период в BP)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {number} period
   *    @param {string} date
   *  }
   */
   static fetchSpreadValuesData(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/values/spreads${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения данных 'Отклонение спреда' (Размер улучшения спреда на СПБ относительно сравниваемого рынка )
   *  (Используется для получения значений средневзвешенного размера улучшения спреда по инструменту относительно сравниваемого рынка,
   *   агрегированные за указанный период в bp. Возвращает данные за весь торговый день)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {number} period
   *    @param {string} date
   *  }
   */
   static fetchDeltaSpreadsData(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/delta/spreads${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения данных 'Объем торгов акциями на бирже'
   *  Получить Данные По Объёму Торгов (Агрегированные данные по объему торгов в USD по рынкам)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {number} period
   *    @param {string} date
   *  }
   */
   static fetchVolumesData(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/values/volumes${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения данных 'Размер достпуного средне взвешенного объема внутри лучших котировок'
   *  (Используется для получения средневзвешенных значений объема в валюте цены и количества в лотах на уровне лучших котировок для рынка СПБ)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {number} period
   *    @param {string} date
   *  }
   */
   static fetchValuesInsideData(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/inside/volume${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения данных 'Размер улучшения исполнения на СПБ относительно сравниваемого рынка'
   *  (Используется для получения значений средневзвешенного размера улучшения исполнения по инструменту относительно сравниваемого рынка, агрегированные за указанный период в bp.
   *   Возвращает данные за весь торговый день.)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {number} period
   *    @param {string} date
   *  }
   */
   static fetchDeltaExecutionData(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/delta/execution${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения данных 'Сравнение исполнения заявок между рынками'
   *  (Используется для получения результатов сравнения лучших цен сравниваемого рынка и цены исполненной сделки на спб за указанный период в bp, агрегированные за указанный период)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {number} period
   *    @param {string} date
   *  }
   */
   static fetchCompareExecutionData(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/compare/execution${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }
}
