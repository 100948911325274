import * as types from './types';

const initState = {
  status: {
    loading: false,
    success: false,
    error: false,
  },
  logged: false,
};

export default function reduce(state = initState, action = {}) {
  switch (action.type) {
    case types.SET_LOG_IN_STATUS:
      return {
        ...state,
        logged: action?.logged,
      };

    case types.FORGOT_PASSWORD:
      return {
        ...state,
      };

    case types.SET_LOADING_STATUS:
      return {
        ...state,
        status: {
          ...state?.status,
          loading: action?.loading,
          success: action?.success,
          error: action?.error,
        },
      };

    default:
      return state;
  }
};

/** статус загрузки данных авторизации */
export const getLoadingStatus = state => {
  return state?.auth?.status;
};

/** маркер - залогирован ли пользователь */
export const getLogInStatus = state => {
  return state?.auth?.logged;
};
