import React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

import { colors } from '../../config/colors';

import './style.css';

const Button = styled(LoadingButton)({
  backgroundColor: colors.blue,
  minWidth: '120px',
  maxWidth: '220px',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: colors.violet,
    color: colors.blue,
  },
  '&:disabled': {
    backgroundColor: colors.lightGray,
    color: colors.gray,
    border: `1px solid ${colors.gray}`,
  },
  '&.MuiLoadingButton-loading': {
    backgroundColor: colors.violet,
    color: colors.blue,
    border: 'none',
  },
});

/** компонент отрисовки кастомизированной кнопки */
export default function ButtonItem(props) {
  const {
    /** метод-обработчик кнопки */
    onClick,
    /** текст кнопки */
    title,
    /** опционально - символ ДО текста кнопки */
    startIcon,
    /** опционально - символ ПОСЛЕ текста кнопки */
    endIcon,
    /** маркер - индикатор загрузки */
    loading,
    ...otherProps
  } = props;

  return (
    <Button
      variant="contained"
      onClick={onClick}
      startIcon={startIcon ?? <></>}
      endIcon={endIcon ?? <></>}
      loading={loading}
      loadingPosition="end"
      {...otherProps}
    >
      { title }
    </Button>
  )
}
