import React, { Fragment, useEffect, useState } from 'react'
import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { chartColors } from '../../../config/colors';
import '../style.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const colorsQueue = {"SPBEX": chartColors.green};

/** компонент отрисовки линейного графика (с динамическим количеством линий) */
export default function LineChart(props) {
  const {
    /** данные графика */
    dataset,
    /** маркер малого размера устройства */
    smallScreen,
    /** заголовок графика */
    titleText,
    /** отображать ли ось У */
    showScaleY,
  } = props;

  /** список label'ов (timestamp'ов) графиков (ось Х) */
  const [timestamp, setTimestamp] = useState([]);
  /** список данных по рынкам (ось У) */
  const [chartDataset, setDatasets] = useState([]);

  useEffect(() => {
    /** выбираем из исходного датасета данные для оси Х и переводим их в формат 'HH:mm' */
    setTimestamp(dataset?.map(elem => moment(elem?.timestamp).format('HH:mm')));
    const data = dataset?.map(elem => elem?.data) ?? [];
    /** из первой записи выбираем все ключи (названия рынков) */
    const markets = !!data.length ? Object.keys(data.reduce((a, b) => Object.keys(a).length >= Object.keys(b).length ? a : b)) : [];
    /** для каждого рынка указываем заголовок графика, цвет и список значений по оси Y, соответствующих каждой точке оси X */
    const marketsData = markets?.map((market, ind) => {
      return {
        label: market,
        data: data?.map(item => item[market]),
        backgroundColor: colorsQueue[market] ?? chartColors.blue,
        borderColor: colorsQueue[market] ?? chartColors.blue,
      };
    });
    setDatasets(marketsData);
  }, [dataset]);

  const chartData = { 
    labels: timestamp, 
    datasets: chartDataset,
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      /** заголовок графика */
      title: {
        display: false,
        text: titleText,
        align: smallScreen ? 'start' : 'center',
      },
      /** легенада (описание line) */
      legend: {
        display: true,
        align: 'end',
        labels: {
          boxWidth: 5,
          usePointStyle: true,
        },
      },
    },
    scales: {
      /** ось Х */
      x: {
        /** количество отрезков по оси Х (для малых экранов их меньше, чем для больших) */
        ticks: { maxTicksLimit: smallScreen ? 11 : 20 },
      },
      /** ось У */
      y: {
        display: showScaleY,
      },
    },
  };

  return (
    <Fragment>
      <Line options={options} data={chartData} />
    </Fragment>
  )
}
