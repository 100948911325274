import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import setupAxiosInterceptors from './config/axios.interceptors';

import { store } from './store/configureStore';

import Backdrop from './components/BackDrop';
import Main from './containers/Main';

import { router } from './config/router';

import './index.css';

const LogIn = lazy(() => import('./containers/LogIn'));
const SignUp = lazy(() => import('./containers/SignUp'));

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['ru'],
    fallbackLng: 'ru',
    debug: false,
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  })

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Backdrop open={true} center={true} />}>
      <Provider store={store}>
        <React.StrictMode>
          <Routes>
            <Route exact path={router.home} element={<Main />} />
            <Route path={router.login} element={<LogIn />} />
            <Route path={router.signup} element={<SignUp />} />
          </Routes>
        </React.StrictMode>
      </Provider>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

setupAxiosInterceptors();
