import * as types from './types';

const initState = {
  screenSize: 0,
  smallScreen: false,
};

export default function reduce(state = initState, action = {}) {
  switch (action.type) {
    case types.SET_SCREEN_SIZE:
      return {
        ...state,
        screenSize: action?.data,
      };

    case types.SET_SMALL_SCREEN:
      return {
        ...state,
        smallScreen: action?.data,
      };
    default:
      return state;
  }
};

/** размер экрана */
export const getScreenSize = state => {
  return state?.service?.screenSize;
};

/** маркер малого размера экрана */
export const getSmallScreen = state => {
  return state?.service?.smallScreen;
};
