import * as types from './types';

const initState = {
  status: {
    loading: false,
    success: false,
    error: false,
  },
  currentUser: {},
  userById: {},
};

export default function reduce(state = initState, action = {}) {
  switch (action.type) {
    case types.FETCH_CURRENT_USER:
      return {
        ...state,
        currentUser: action?.userData,
      };

    case types.FETCH_USER_BY_ID:
      return {
        ...state,
        userById: action?.userData,
      };

    case types.SET_LOADING_STATUS:
      return {
        ...state,
        status: {
          ...state?.status,
          loading: action?.loading,
          success: action?.success,
          error: action?.error,
        },
      };

    default:
      return state;
  }
};

/** индикатор загрузки данных пользователя */
export const getLoadingStatus = state => {
  return state?.users?.status;
};

/** данные текущего пользователя */
export const getCurrentUser = state => {
  return state?.users?.currentUser;
};

/** данные пользователя по id */
export const getUserById = state => {
  return state?.users?.userById;
};
