export default class HelperService {
  /** метод преобразует сисок параметров в строку с queryParams */
  static objectToQueryParams = (obj) => {
    if (!obj) return '';
    return Object.keys(obj).reduce((acc, key) => {
      if (obj[key]) {
        if (acc !== '') {
          acc += '&';
        }
        acc += `${key}=${encodeURIComponent(obj[key])}`;
      }
      return acc;
    }, '');
  }
}
