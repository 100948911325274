import axios from 'axios';
import HelperService from './helper';

export default class ReportService {
  static baseApiUrl = 'api/v1/reports';

  /** метод получения отчета 'Сравнение лучших цен по сторонам между рынками'
   *  (Возвращает файл с результатами сравнения лучших цен по сторонам между рынками)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {string} date
   *  }
   */
  static fetchCompareSidesReport({ period, ...data }) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/compare/sides${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения отчета 'Сравнение спредов по инструменту между рынками'
   *  (Возвращает файл с результатами сравнения спредов по инструменту между рынками)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {string} date
   *  }
   */
   static fetchCompareSpreadsReport({ period, ...data }) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/compare/spreads${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения отчета 'Сравнений значений средневзвешенного спреда по инструменту для основного и сравниваемого рынка'
   *  (Возвращает файл с результатами значений средневзвешенного спреда по инструменту для основного и сравниваемого рынка)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {string} date
   *  }
   */
   static fetchValuesSpreadsReport({ period, ...data }) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/values/spreads${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения отчета 'Сравнение значений средневзвешенного размера улучшения спреда по инструменту относительно сравниваемого рынка,
   *  агрегированные за указанный период в вр'
   *  (Возвращает данные за весь торговый день)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {string} date
   *  }
   */
   static fetchDeltaSpreadsReport({ period, ...data }) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/delta/spreads${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения отчета 'Объем торгов по сравниваемому рынку и рынку СПБ'
   *  (Используется для получения значений объема торгов по сравниваемому рынку и рынку СПБ)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {string} date
   *  }
   */
   static fetchVolumesReport({ period, ...data }) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/values/volumes${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения отчета 'Размер достпуного средне взвешенного объема внутри лучших котировок'
   *  (Используется для получения отчёта средневзвешенных значений объема)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {string} date
   *  }
   */
   static fetchValuesInsideReport({ period, ...data }) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/inside/volume${params}`)
      .then(res => res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения отчета 'Сравнения исполнения заявок между рынками'
   *  (Используется для получения результатов сравнения лучших цен сравниваемого рынка и цены исполненной сделки на спб за указанный период в bp,
   *  агрегированные за указанный период)
   *  @param data = {
   *    @param {string} market
   *    @param {string} symbol
   *    @param {string} date
   *  }
   */
     static fetchCompareExecutionReport({ period, ...data }) {
      const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
      return axios
        .get(`${this.baseApiUrl}/compare/execution${params}`)
        .then(res => res?.data ?? [])
        .catch(error => {
          return Promise.reject(error);
        });
    }
  
    /** метод получения отчета 'По размеру улучшения исполнения на спб относительно сравниваемого рынка'
     *  (Используется для получения значений средневзвешенного размера улучшения исполнения по инструменту относительно сравниваемого рынка,
     *  агрегированные за указанный период в bp. Возвращает данные за весь торговый день.)
     *  @param data = {
     *    @param {string} market
     *    @param {string} symbol
     *    @param {string} date
     *  }
     */
     static fetchDeltaExecutionReport({ period, ...data }) {
      const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
      return axios
        .get(`${this.baseApiUrl}/delta/execution${params}`)
        .then(res => res?.data ?? [])
        .catch(error => {
          return Promise.reject(error);
        });
    }
}
