import React, {Fragment, useRef, useState} from 'react';

import {connect} from 'react-redux';
import * as chartsSelectors from '../../store/charts/reducers';

import {CSVLink} from "react-csv";

import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Typography from '@mui/material/Typography';

import {InformationOutline} from 'mdi-material-ui';

import ReportService from '../../service/report';

import {colors} from '../../config/colors';

import './style.css';
import Alert from "../../components/Alert";

/** компонент отрисовки Заголовка и функциональных кнопок карточки графиков */
const ChartHeader = (props) => {
    const {
        /** тип графика */
        type,
        /** маркер малого размера устройства */
        smallScreen,
        /** маркер - залогинен ли пользователь */
        logged,
        /** данные параметров запроса */
        requestParams,
    } = props;

    /** ссылка на элемент, к которому будет прикрепляться Popper (меню выбора яз) - в данном случае кнопка с выбором языка */
    const anchorRef = useRef(null);
    /** маркер - отображать ли меню выбора языка */
    const [open, setOpen] = useState(false);
    /** данные отчета */
    const [report, setReport] = useState('');
    /** лоадер на кнопке скачивания */
    const [reportLoader, setReportLoader] = useState(false);
    /** маркер - отображать ли информационное сообщение (алерт) */
    const [showAlert, setShowAlert] = useState(false);
    /** текст алерта */
    const [alertTitle, setAlertTitle] = useState('');
    /** ссылка на элемент CSVLink для скачивания документа */
    const csvLink = useRef();

    /** t - функция, которая дает доступ к конфигу translations */
    const {t} = useTranslation();

    /** метод-обработчик вызовы окна с подсказкой */
    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    /** метод-обработчик взаимодействия со всем пространством за исключением меню выбора языка (ClickAwayListener) */
    const handleClose = (event) => {
        if (anchorRef?.current.contains(event?.target)) return;
        setOpen(false);
    };

    /** название графиков */
    const getHeaderText = () => {
        return t(`charts.${type}.header`);
    }

    /** информационное сообщение в окне-подсказке */
    const getPopperText = () => {
        return t(`charts.${type}.popper`);
    }

    /** заголовок файла */
    const getReportTitle = () => {
        return `${requestParams?.symbol}_${t(`charts.${type}.report_title`)}`;
    }

    /** метод-обработчик закрытия информационного сообщения */
    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    /** метод запроса отчета */
    const fetchReport = (type) => {
        switch (type) {
            case 'compare_sides':
                return ReportService.fetchCompareSidesReport(requestParams);
            case 'compare_spreads':
                return ReportService.fetchCompareSpreadsReport(requestParams);
            case 'spread_values':
                return ReportService.fetchValuesSpreadsReport(requestParams);
            case 'delta_spread':
                return ReportService.fetchDeltaSpreadsReport(requestParams);
            case 'volumes':
                return ReportService.fetchVolumesReport(requestParams);
            case 'values_inside':
                return ReportService.fetchValuesInsideReport(requestParams);
            // case 'delta_execution':
            //   return ReportService.fetchCompareExecutionReport(requestParams);
            // case 'compare_execution':
            //   return ReportService.fetchDeltaExecutionReport(requestParams);
            default:
                return;
        }
    }

    /** метод-обработчик конпки скачивания файла */
    const onHandleReport = async () => {
        try {
            setReportLoader(true);
            setAlertTitle("Отчёт формируется и будет отправлен вам на почту");
            setShowAlert(true);
            await fetchReport(type)
                .then(res => {
                    setReport(res);
                });
            csvLink?.current?.link?.click();
            setReportLoader(false);
        } catch (err) {
            setReportLoader(false);
        }
    }

    return (
        <Fragment>
            <Typography sx={{color: colors.darkBlue, fontSize: '20px', fontWeight: 'bold'}}>
                {getHeaderText()}
            </Typography>

            <Box sx={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-end'}}>
                {
                    logged &&
                    <Fragment>
                        <IconButton onClick={onHandleReport} disabled={reportLoader}>
                            {
                                reportLoader
                                    ? <CircularProgress sx={{color: colors.lightBlue}} size={20}/>
                                    : <SaveAltIcon sx={{color: colors.lightBlue}}/>
                            }
                        </IconButton>

                        {/* <CSVLink
              filename={getReportTitle()}
              target="_blank"
              data={report}
              ref={csvLink}
            /> */}
                        <Alert
                            open={showAlert}
                            title={alertTitle}
                            handleClose={handleCloseAlert}
                        />
                    </Fragment>
                }

                <IconButton
                    ref={anchorRef}
                    sx={{zIndex: 1}}
                    onClick={handleClick}
                >
                    <InformationOutline sx={{color: colors.lightBlue, zIndex: 1}}/>
                </IconButton>
            </Box>

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                className="popper"
            >
                {({TransitionProps}) => (
                    <Grow {...TransitionProps} >
                        <Paper
                            elevation={0}
                            variant="outlined"
                            sx={{
                                maxWidth: smallScreen ? '300px' : '500px',
                                padding: '15px',
                                backgroundColor: '#FAF7F1',
                                margin: '0 5% 0 0',
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                                    <p>{getPopperText()}</p>
                                    <IconButton onClick={handleClose}>
                                        <CloseOutlinedIcon/>
                                    </IconButton>
                                </Box>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        requestParams: chartsSelectors.getRequestParams(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartHeader);