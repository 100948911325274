import * as types from './types';

const initState = {
  status: {
    loading: false,
    success: false,
    error: false,
  },

  chartsData: {
    compareSidesCharts: [],
    compareSpreadsCharts: [],
    spreadValuesCharts: [],
    deltaSpreadCharts: [],
    volumesCharts: [],
    valuesInsideCharts: [],
    deltaExecutionCharts: [],
    compareExecutionCharts: [],
  },

  requestParams: {
    market: '',
    symbol: '',
    date: '',
    group: 'index',
    period: 900,
  },
};

export default function reduce(state = initState, action = {}) {
  switch (action.type) {
    case types.SET_CHARTS_DATA:
      return {
        ...state,
        chartsData: {
          ...state.chartsData,
          ...action?.data,
        },
      };

    case types.SET_LOADING_STATUS:
      return {
        ...state,
        status: {
          ...state?.status,
          loading: action?.loading,
          success: action?.success,
          error: action?.error,
        },
      };

    case types.SET_REQUEST_DATA:
      return {
        ...state,
        requestParams: {
          ...state?.requestParams,
          market: action?.params?.market,
          symbol: action?.params?.symbol,
          period: action?.params?.period,
          date: action?.params?.date,
          group: action?.params?.group,
        },
      };

    default:
      return state;
  }
};

/** данные графиков */
export const getChartsData = state => {
  return state?.charts?.chartsData;
};

/** статус загрузки данных графиков */
export const getLoadingStatus = state => {
  return state?.charts?.status;
};

/** данные параметров запросов */
export const getRequestParams = state => {
  return state?.charts?.requestParams;
};
