import React, { Fragment, useEffect } from 'react';

import moment from 'moment';
import cookies from 'js-cookie';
import { enGB, ru } from 'date-fns/locale';
import { parseISO } from 'date-fns';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import * as chartsActions from '../../store/charts/actions';
import * as authSelectors from '../../store/auth/reducers';
import * as chartsSelectors from '../../store/charts/reducers';
import * as informationSelectors from '../../store/information/reducers';
import * as serviceSelectors from '../../store/service/reducers';
import * as informationActions from '../../store/information/actions';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

import { colors, chartColors } from '../../config/colors';

import './style.css';

const CustomButton = styled(ToggleButton)({
  border: `2px solid ${chartColors.lightBlue} !important`,
  minWidth: '125px',
  width: '100%',
  '&.MuiToggleButton-root': {
    fontWeight: 'bold',
    color: chartColors.lightBlue,
    '&.Mui-selected': {
      backgroundColor: colors.violet,
      color: chartColors.blue,
    },
    '&.Mui-disabled': {
      color: colors.gray,
    },
  },
});

const CustomTextField = styled(TextField)({
  border: `2px solid ${chartColors.lightBlue}`,
  borderRadius: '4px',
  minWidth: '200px',
  '& .MuiOutlinedInput-root': {
    fontSize: '16px',
    color: chartColors.blue,
    '& fieldset': {
      border: 'none',
    },
    '& .MuiButtonBase-root': {
      color: chartColors.blue,
      fontSize: '16px',
      '&.Mui-disabled': {
        color: colors.gray,
      },
    },
  },
});

/** маски полей в зависимости от локализации */
const maskMap = {
  en: '__/__/____',
  ru: '__.__.____',
};

const localeMap = {
  en: enGB,
  ru,
};

/* компонент параметров настройки данных графиков */
function Toolbar(props) {
  const {
    /** === store === */
    /** маркер малого размера устройства */
    smallScreen,
    /** список рынков */
    markets,
    /** выпадающий список тикеров */
    tickers,
    /** список дат */
    dates,
    /** данные параметров запроса */
    requestParams,
    /** залогинен ли пользователь */
    logged,

    /** метод установки в стор данных запросов */
    setRequestData,
    /** метод загрузки данных инструментов */
    fetchTickers,
  } = props;

  /** t - функция, которая дает доступ к конфигу translations */
  const { t } = useTranslation();

  /** код текущего языка, по умолчанию - en */
  const currentLanguageCode = cookies?.get('i18next') ?? 'en';

  useEffect(() => {
    if (smallScreen) {
      setRequestData({ ...requestParams, period: 1800 });
    } else {
      setRequestData({ ...requestParams, period: 900 });
    }
    // eslint-disable-next-line
  }, [smallScreen]);

  /** метод-обработчик выбора рынка */
  const handleAlignment = (value) => {
    if (value !== null) {
      setRequestData({ ...requestParams, market: value });
    }
    fetchTickers({ ...requestParams, market: value });
  };

  const handleGroup = async (value) => {
    const tickers = await fetchTickers({...requestParams, group: value});
    setRequestData({...requestParams, group: value, symbol: tickers?.find(elem => elem?.default)?.symbol ?? ''});
  };

  /** метод-обработчик выбора тикера из списка */
  const handleSelect = (value) => {
    setRequestData({ ...requestParams, symbol: value?.symbol });
  };

  /** метод-обработчик выбора даты */
  const handleDate = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    setRequestData({ ...requestParams, date: date });
    fetchTickers({ ...requestParams, date: date });
  };

  /** метод-обработчик убираем лишние даты */
  const disableUnknownDates = (date) => {
    const findDate = dates?.listDates.find(
      d => d === moment(date).format('YYYY-MM-DD'),
    );
    return findDate === undefined;
  }

  return (
    <Fragment>
      <Box className="tools-wrapper" sx={{
        width: '100%',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        rowGap: '10px',
        columnGap: smallScreen ? '25px': '50px',
      }}>
        <ToggleButtonGroup
          color="primary"
          value={requestParams?.market}
          exclusive
          onChange={(event, value) => handleAlignment(value)}
          aria-label="text alignment"
          sx={{ ...smallScreen && { height:  '45px', width: '100%' } }}
        >
          {
            markets?.map(elem => (
              <CustomButton
                key={elem?.market}
                value={elem?.market}
                disabled={!elem?.active}
              >
                {elem?.market}
              </CustomButton>
            ))
          }
        </ToggleButtonGroup>

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={tickers}
          value={requestParams?.symbol}
          disableClearable={true}
          size={smallScreen ? "small" : "normal"}
          renderInput={(params) =><CustomTextField {...params} placeholder={t("form_fields.ticker_label")} className="field_autocomplite" />}
          sx={{ flex: '1 1 auto', width: 'auto' }}
          onChange={(event, value) => handleSelect(value)}
        />

        <ToggleButtonGroup
          color="primary"
          value={requestParams?.group}
          exclusive
          onChange={(event, value) => handleGroup(value)}
          aria-label="text alignment"
          sx={{ ...smallScreen && { height:  '45px', width: '100%' } }}
        >
          <CustomButton key="index" value="index">Index</CustomButton>
          <CustomButton key="stocks" value="stocks">Stocks</CustomButton>
        </ToggleButtonGroup>

        <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap[currentLanguageCode]}>
          <DatePicker
            mask={maskMap[currentLanguageCode]}
            value={parseISO(requestParams?.date)}
            onChange={value => handleDate(value)}
            shouldDisableDate={disableUnknownDates}
            disabled={!logged}
            renderInput={(params) => <CustomTextField
                                        {...params}
                                        inputProps={{ ...params?.inputProps, disabled: true }} className="field_date"
                                        size={smallScreen ? "small" : "normal"}
                                      />}
          />
        </LocalizationProvider>
      </Box>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    requestParams: chartsSelectors.getRequestParams(state),
    tickers: informationSelectors.getTickers(state),
    markets: informationSelectors.getMarkets(state),
    dates: informationSelectors.getDates(state),
    logged: authSelectors.getLogInStatus(state),
    smallScreen: serviceSelectors.getSmallScreen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRequestData: (data) => dispatch(chartsActions.setRequestData(data)),
    fetchTickers: (data) => dispatch(informationActions.fetchTickers(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar)
