export const SET_LOADING_STATUS = 'charts.SET_LOADING_STATUS';
export const SET_CHARTS_DATA = 'charts.SET_CHARTS_DATA';

export const FETCH_COMPARE_SIDES_DATA = 'charts.FETCH_COMPARE_SIDES_DATA';
export const FETCH_COMPARE_SPREADS_DATA = 'charts.FETCH_COMPARE_SPREADS_DATA';
export const FETCH_SPREAD_VALUES_DATA = 'charts.FETCH_SPREAD_VALUES_DATA';
export const FETCH_DELTA_SPREADS_DATA = 'charts.FETCH_DELTA_SPREADS_DATA';
export const FETCH_VOLUMES_DATA = 'charts.FETCH_VOLUMES_DATA';
export const FETCH_VALUES_INSIDE_DATA = 'charts.FETCH_VALUES_INSIDE_DATA';
export const FETCH_DELTA_EXECUTION_DATA = 'charts.FETCH_DELTA_EXECUTION_DATA';
export const FETCH_COMPARE_EXECUTION_DATA = 'charts.FETCH_COMPARE_EXECUTION_DATA';

export const SET_REQUEST_DATA = 'charts.SET_REQUEST_DATA';
