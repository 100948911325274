export const colors = {
  blue: '#2434DB',
  darkBlue: '#26265E',
  lightBlue: '#03A6E5',
  green: '#5AF3C6',
  violet: '#D6DDF7',
  gray: '#AAAAAA',
  lightGray: '#F2F2F2',
};

export const chartColors = {
  gray: '#9B98A3',
  green: '#45DCA2',
  red: '#FF6C69',
  blue: '#7175D8',
  lightBlue: '#D6DDF6',
};