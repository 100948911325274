import axios from 'axios';
import HelperService from './helper';

export default class InformationService {
  static baseApiUrl = 'api/v1/information';

  /** метод получения списка рынков */
  static fetchMarkets() {
    return axios
      .get(`${this.baseApiUrl}/markets`)
      .then(res =>  res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения выпадающего списка инструментов */
  static fetchTickers(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/instruments${params}`)
      .then(res =>  res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /** метод получения списка досупныъ дат */
  static fetchDates(data) {
    const params = data ? `?${HelperService.objectToQueryParams(data)}` : '';
    return axios
      .get(`${this.baseApiUrl}/dates${params}`)
      .then(res =>  res?.data ?? [])
      .catch(error => {
        return Promise.reject(error);
      });
  }
}