import { combineReducers } from 'redux';

import auth from './auth/reducers';
import charts from './charts/reducers';
import information from './information/reducers';
import service from './service/reducers';
import users from './users/reducers';

const reducers = {
   auth,
   charts,
   information,
   service,
   users,
};

export const rootReducer = combineReducers({
   ...reducers,
});
