import * as types from './types';
import AuthService from '../../service/auth';

/** метод регистрации пользователя */
export const registrateUser = (formData) => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });
    const response = await AuthService.register(formData);
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: false, error: true });
    throw new Error(err?.response?.data?.detail ?? err);
  }
};

/** метод авторизации (логина) */
export const logIn = (formData) => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });
    const response = await AuthService.login(formData);
    dispatch({ type: types.SET_LOG_IN_STATUS, logged: true });
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: false, error: true });
    dispatch({ type: types.SET_LOG_IN_STATUS, logged: false });
    throw new Error(err?.response?.data?.detail ?? err);
  }
};


/** метод выхода из аккаунта (логаута) */
export const logOut = () => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });
    const response = AuthService.logout();
    dispatch({ type: types.SET_LOG_IN_STATUS, logged: false });
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: false, error: true });
    throw new Error(err?.response?.data?.detail ?? err);
  }
};

/** метод восстановления пароля */
export const forgotPassword = (formData) => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });
    const response = await AuthService.forgotPassword(formData);
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: false, error: true });
    throw new Error(err?.response?.data?.detail ?? err);
  }
};
