import React, { Fragment, useEffect, useState } from 'react';

import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { chartColors } from '../../../config/colors';

import '../style.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);  

const colorsQueue = [chartColors.blue, chartColors.green, chartColors.lightBlue, chartColors.red];

/** компонент отрисовки графика barchart по bp */
export default function BPBarChart(props) {
  const {
    /** данные графика */
    dataset,
    /** маркер малого размера устройства */
    smallScreen,
    /** заголовок графика */
    titleText,
    /** label */
    labelText,
    /** отображать ли ось У */
    showScaleY,
  } = props;

  /** массив значений "value" (ось Y) */
  const [value, setValue] = useState([]);
  /** список label'ов графиков (ось Х) */
  const [bp, setBP] = useState([]);
  /** цвет графика */
  const [barColor, setBarColor] = useState(colorsQueue[0]);

  useEffect(() => {
    const sortedKeys = Object.keys(dataset?.data ?? {})?.sort((a, b) => a - b);
    const sortedValues = sortedKeys?.map(key => dataset?.data[key]);
    setBP(sortedKeys);
    setValue(sortedValues);
    setBarColor(getRandomColor());
  }, [dataset]);

  const getRandomColor = () => {
    const rand = Math.floor(Math.random() * colorsQueue?.length);
    return colorsQueue[rand];
  }

  const chartData = {
    labels: bp, 
    datasets: [
      {
        label: labelText,
        data: value,
        backgroundColor: barColor,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      /** заголовок графика */
      title: {
        display: false,
        text: titleText,
        align: smallScreen ? 'start' : 'center',
      },
      /** легенада (описание line) */
      legend: {
        display: true,
        align: 'end',
        labels: {
          boxWidth: 5,
          usePointStyle: true,
        },
      },
    },
    scales: {
      /** ось Х */
      x: {
        title: {
          display: true,
          text: 'Разница спрэдов, bps'
        },
        /** количество отрезков по оси Х */
        ticks: { maxTicksLimit: 12 },
      },
      /** ось У */
      y: {
        title: {
          display: true,
          text: '%'
        },
        display: showScaleY,
        /** количество отрезков по оси У */
        ticks: {  maxTicksLimit: 10 },
      },
    },
  };

  return (
    <Fragment>
      <Bar options={options} data={chartData} />
    </Fragment>
  )
}
