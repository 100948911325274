import React, { Fragment } from 'react'

import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

import BPBarChart from './charts/BPBarChart';
import CompareBarChart from './charts/CompareBarChart';
import LineChart from './charts/LineChart';
import ValuesInsideLineChart from './charts/ValuesInsideLineChart';
import VolumesBarChart from './charts/VolumesBarChart';

import { chartColors } from '../../config/colors';
import './style.css';

/** компонент отрисовки данных карточки графиков */
export default function Chart(props) {
  const {
    /** данные графика */
    data,
    /** тип графика */
    type,
    /** маркер малого размера устройства */
    smallScreen,
    /** размер экрана */
    screenSize,
  } = props;

  /** t - функция, которая дает доступ к конфигу translations */
  const { t } = useTranslation();

  const renderCharts = () => {
    switch (type) {
      case 'compare_sides':
        const askCompareSides = data?.map(elem => ({ ...elem, data: elem?.data?.ask }));
        const bidCompareSides = data?.map(elem => ({ ...elem, data: elem?.data?.bid }));
        return (
          <Fragment>
            <Box className="chart-content">
              <CompareBarChart
                name='compare_sides'
                dataset={bidCompareSides}
                smallScreen={smallScreen}
                showScaleY={screenSize > 768}
                titleText={t('charts.compare_sides.title_bid')}
                border={true}
              />
            </Box>
            <Box className="chart-content">
              <CompareBarChart
                name='compare_sides'
                dataset={askCompareSides}
                smallScreen={smallScreen}
                showScaleY={screenSize > 768}
                titleText={t('charts.compare_sides.title_ask')}
                border={true}
              />
            </Box>
          </Fragment>
        );

        case 'compare_spreads':
          return (
            <Fragment>
              <Box className="chart-content_wide">
                <CompareBarChart
                  name='compare_spreads'
                  dataset={data}
                  smallScreen={smallScreen}
                  showScaleY={screenSize > 768}
                  titleText={t('charts.compare_spreads.title')}
                  border={true}
                />
              </Box>
            </Fragment>
          );

        case 'spread_values':
          return (
            <Fragment>
              <Box className="chart-content_single">
                <LineChart
                  dataset={data}
                  smallScreen={smallScreen}
                  showScaleY={screenSize > 768}
                  titleText={t('charts.spread_values.title')}
                />
              </Box>
            </Fragment>
          );

        case 'delta_spread':
          return (
            <Fragment>
              <Box className="chart-content_single">
                <BPBarChart
                  dataset={data}
                  smallScreen={smallScreen}
                  showScaleY={screenSize > 768}
                  titleText={t('charts.delta_spread.title')}
                  labelText={t('charts.delta_spread.legend')}
                />
              </Box>
            </Fragment>
          );

        case 'volumes':
          return (
            <Fragment>
              <Box className="chart-content_wide">
                <VolumesBarChart
                  dataset={data}
                  marketName="NASDAQ"
                  smallScreen={smallScreen}
                  showScaleY={screenSize > 768}
                  titleText={t('charts.volumes.title')}
                  tooltip={data?.[0]?.currency ?? ''}
                />
              </Box>
            </Fragment>
          );

        case 'values_inside':
          const valuesInsideAsk = data?.map(elem => ({ ...elem, data: elem?.data?.ask }));
          const valuesInsideBik = data?.map(elem => ({ ...elem, data: elem?.data?.bid }));
          return (
            <Fragment>
              <Box className="chart-content">
                <ValuesInsideLineChart
                  dataset={valuesInsideBik}
                  smallScreen={smallScreen}
                  showScaleY={screenSize > 768}
                  titleText={t('charts.values_inside.title_bid')}
                  barColor={chartColors.red}
                />
              </Box>
              <Box className="chart-content">
                <ValuesInsideLineChart
                  dataset={valuesInsideAsk}
                  smallScreen={smallScreen}
                  showScaleY={screenSize > 768}
                  titleText={t('charts.values_inside.title_ask')}
                  barColor={chartColors.green}
                />
              </Box>
            </Fragment>
          );

        // case 'delta_execution':
        //   return (
        //     <Fragment>
        //       <Box className="chart-content_single">
        //         <BPBarChart
        //           dataset={data}
        //           smallScreen={smallScreen}
        //           showScaleY={screenSize > 768}
        //           titleText={t('charts.delta_execution.title')}
        //           labelText={t('charts.delta_execution.legend')}
        //         />
        //       </Box>
        //     </Fragment>
        //   );

        // case 'compare_execution':
        //   return (
        //     <Fragment>
        //       <Box className="chart-content_single">
        //         <CompareBarChart
        //           dataset={data}
        //           smallScreen={smallScreen}
        //           showScaleY={screenSize > 768}
        //           titleText={t('charts.compare_execution.title')}
        //           tooltip={data?.[0]?.currency ?? ''}
        //         />
        //       </Box>
        //     </Fragment>
        //   );

      default:
        return null;
    }
  }

  return (
    <Fragment>
      { renderCharts() }
    </Fragment>
  )
}
