import * as types from './types';
import ChartService from '../../service/charts';

/** метод получения данных 'Сравнение по сторонам спреда' */
export const fetchCompareSidesData = (data) => async (dispatch) => {
  try {
    const response = await ChartService.fetchCompareSidesData(data);
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод получения данных 'Результат сравнения спредов' */
export const fetchCompareSpreadsData = (data) => async (dispatch) => {
  try {
    const response = await ChartService.fetchCompareSpreadsData(data);
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод получения данных 'Профиль размера спреда' */
export const fetchSpreadValuesData = (data) => async (dispatch) => {
  try {
    const response = await ChartService.fetchSpreadValuesData(data);
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод получения данных 'Отклонение спреда' */
export const fetchDeltaSpreadsData = ({ period, ...data }) => async (dispatch) => {
  try {
    const response = await ChartService.fetchDeltaSpreadsData(data);
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод получения данных 'Объем торгов акциями на бирже' */
export const fetchVolumesData = (data) => async (dispatch) => {
  try {
    const response = await ChartService.fetchVolumesData(data);
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод получения данных 'Размер достпуного средне взвешенного объема внутри лучших котировок' */
export const fetchValuesInsideData = (data) => async (dispatch) => {
  try {
    // TODO: рынок - всегда захардкожен, дата - временная
    const response = await ChartService.fetchValuesInsideData({...data, market: 'SPBEX'});
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод получения данных 'Размер улучшения исполнения на СПБ относительно сравниваемого рынка' */
export const fetchDeltaExecutionData = ({ period, ...data }) => async (dispatch) => {
  try {
    const response = await ChartService.fetchDeltaExecutionData(data);
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод получения данных 'Сравнение исполнения заявок между рынками' */
export const fetchCompareExecutionData = (data) => async (dispatch) => {
  try {
    const response = await ChartService.fetchCompareExecutionData(data);
    return response;
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** метод загрузки данных всех графиков */
export const fetchChartsData = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.SET_LOADING_STATUS, loading: true, success: false, error: false });

    const [
        compareSidesCharts, compareSpreadsCharts, spreadValuesCharts,
        deltaSpreadCharts, volumesCharts, valuesInsideCharts
    ] = await Promise.all([
		dispatch(fetchCompareSidesData(data)),
        dispatch(fetchCompareSpreadsData(data)),
		dispatch(fetchSpreadValuesData(data)),
        dispatch(fetchDeltaSpreadsData(data)),
		dispatch(fetchVolumesData(data)),
        dispatch(fetchValuesInsideData(data))
	])
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, success: true, error: false });
    dispatch({
      type: types.SET_CHARTS_DATA,
      data: {
        compareSidesCharts,
        compareSpreadsCharts,
        spreadValuesCharts,
        deltaSpreadCharts,
        volumesCharts,
        valuesInsideCharts
      },
    });
  } catch (err) {
    dispatch({ type: types.SET_LOADING_STATUS, loading: false, error: true, success: false });
  }
};

/** установка данных параметров запроса в стор */
export const setRequestData = (params) => (dispatch) => {
  dispatch({ type: types.SET_REQUEST_DATA, params });
};